<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0 sticky">
            <div class="d-flex p-0">
                <div class="card-title text-gray-600">
                    <div class="filter d-flex align-items-center position-relative fs-6">
                        <div class="filter-item">
                            <span>CARI</span>
                            <el-input v-model="filter.search" placeholder="Search" clearable />
                        </div>
                        <div class="filter-item">
                            <span>Jabatan</span>
                            <el-select v-model="filter.role" placeholder="Pilih role" clearable>
                                <el-option v-for="(o, i) in listJabatan" :key="i" :value="o.id" :label="o.name"/>
                            </el-select>
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-icon btn-light-primary me-3" @click="onFilter(), currentPage = 1"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter(), currentPage = 1"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-toolbar" style="margin-left:auto">
                    <div class="d-flex justify-content-end" data-kt-table-toolbar="base">
                        <button class="btn btn-primary" @click="modalClick('add', null)">Create</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body pt-0 tab-content">
          <div ref="tableRef" class="table-fixed-header">
            <table class="table table-rounded table-row-dashed table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
              <thead>
                <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                  <th>#</th>
                  <th class="w-125px">Nama</th>
                  <th class="w-125px">Jabatan</th>
                  <th>Email</th>
                  <th>No. Telp</th>
                  <th>Penempatan</th>
                  <th>Alamat</th>
                  <th class="text-end w-125px" v-for="(data, i) in tableStore" :key="i">
                    {{ data.store_name.replace('NIMCO', '') }}
                  </th>
                  <th class="w-125px">Status</th>
                  <th class="text-end w-125px">Action</th>
                </tr>
              </thead>
              <tbody class="fw-bold text-gray-600">
                <tr v-for="(data, i) in tableData" :key="i" class="odd">
                  <td>{{ data.index }}</td>
                  <td>{{ data.nama_user }}</td>
                  <td>{{ data.jabatan || '-' }}</td>
                  <td>{{ data.email || '-' }}</td>
                  <td>{{ data.telp || '-' }}</td>
                  <td>{{ data.store_name || '-' }}</td>
                  <td>{{ data.alamat || '-' }}</td>
                  <td>
                    <span class="badge badge-danger" v-if="data.active == 0">Non Active</span>
                    <span class="badge badge-success" v-if="data.active == 1">Active</span>
                  </td>
                  <td class="text-end">
                     <div class="d-flex gs-1 jsutify-end">
                        <div class="col">
                            <button class="btn btn-secondary btn-sm me-1" title="Edit" @click="onEdit(data)" >
                                <i class="fas fa-edit"></i>
                            </button>
                        </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </table>
          </div>
          <div class="text-center my-3" v-show="tableData.length > 0">
            <el-pagination
              background
              :page-size="50"
              layout="prev, pager, next"
              :page-count="totalPage"
              @next-click="handlePageChange"
              @prev-click="handlePageChange"
              @current-change="handlePageChange"
              @update:current-page="currentPage"
            />
          </div>
        </div>

        <el-dialog v-model="modal" :title="modalTitle" width="600px" :close-on-click-modal="true">
            <Form
                class="form w-100"
                :validation-schema="tempData"
                @submit="onSubmit()"
            >
                <input type="hidden" name="id" v-model="tempData.id" disabled/>
                <div class="scroll-y me-n7 pe-7">
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Nama</label>
                        <Field v-model="tempData.name" type="text" name="name" v-slot="{ field }">
                            <input v-bind="field" class="form-control"/>
                        </Field>
                        <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                                <ErrorMessage name="name" />
                            </div>
                        </div>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Shortname</label>
                        <Field v-model="tempData.shortname" type="text" name="shortname" v-slot="{ field }">
                            <input v-bind="field" class="form-control"/>
                        </Field>
                    </div>
                    <div class="fv-row mb-7" v-if="submitType == 'edit'">
                        <label class="required fs-6 fw-bold mb-2">Status</label>
                        <Field as="select" class="form-select" v-model="tempData.status" name="status">
                            <option v-for="(item, i) in [0,1]" :key="i" :value="item">{{ item == 1 ? "AKTIF" : "NONAKTIF" }}</option>
                        </Field>
                        <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                                <ErrorMessage name="status" />
                            </div>
                        </div>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Tipe</label>
                        <Field as="select" class="form-select" v-model="tempData.tipe" name="tipe">
                            <option v-for="(item, i) in ['WAREHOUSE', 'STORE', 'EVENT']" :key="i" :value="item">{{ item }}</option>
                        </Field>
                        <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                                <ErrorMessage name="tipe" />
                            </div>
                        </div>
                    </div> 
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Deskripsi</label>
                        <Field type="text" name="description" v-model="tempData.description" v-slot="{ field }">
                            <input v-bind="field" class="form-control"/>
                        </Field>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Kode</label>
                        <Field type="text" name="store_code" v-model="tempData.store_code" v-slot="{ field }">
                            <input v-bind="field" class="form-control"/>
                        </Field>
                    </div>
                     <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Alamat</label>
                        <Field v-model="tempData.address" type="text" name="address" v-slot="{ field }">
                            <input v-bind="field" class="form-control"/>
                        </Field>
                        <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                                <ErrorMessage name="address" />
                            </div>
                        </div>
                    </div>
                    <div class="fv-row col file">
                        <label class="required fs-6 fw-bold mb-2">Gambar</label>
                        <input v-show="!tempData.logo" @change="onFileChange()" type="file" ref="image" id="image" class="form-control"/>
                        <div v-if="tempData.logo" class="d-flex">
                            <div class="col me-1">
                                <input class="form-control" :value="tempData.logo.substring(tempData.logo.lastIndexOf('/') + 1)">
                            </div>
                            <div class="col ms-1">
                                <button class="btn btn-light text-link" @click="changeImage('polos')">Change Image</button>
                            </div>
                        </div>
                    </div>
                    <div class="fv-row mb-7 file">
                        <div class="file-upload"></div>
                        <div v-show="tempData.logo" class="preview-image">
                            <img id="preview-image" :src="tempData.logo"/>
                        </div>
                    </div>
                </div>
                <div class="mt-4 mb-2 text-center">
                    <button type="reset" class="btn btn-light me-3" @click="modal = false">Cancel</button>
                    <button
                        type="submit"
                        ref="submitButton"  
                        class="btn btn-primary"
                    >
                        <span class="indicator-label"> Submit </span>

                        <span class="indicator-progress">
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </Form>
        </el-dialog>

        <el-dialog v-model="viewImageVisible" :title="viewImageTitle" width="50%" lock-scroll>
            <inner-image-zoom :src="viewImageSrc" />
        </el-dialog>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox, ElLoading, Elpagination } from 'element-plus'

export default defineComponent({
    components: { 
        //Datatable,
        // Field,
        // Form,
        // ErrorMessage,
        'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const store = useStore();
        const router = useRouter();

        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)
        const img_url_logo= ref(process.env.VUE_APP_IMAGE_LOGOS)

        const loadingTable = ref(false);
        const tableRef = ref(null)

        const filterActive = ref(false)
        const filter = reactive({
            role: null,
            search: null,
        })
        
        const tableHeader = ref([
            {
                name: "#",
                key: "index",
            },
            {
                name: "Nama",
                key: "nama_user",
            },
            {
                name: "Role",
                key: "jabatan",
            },
            {
                name: "Store",
                key: "store_name",
            },
            {
                name: "Username",
                key: "username",
            },
            {
                name: "Email",
                key: "email",
            },
            {
                name: "No. Telp",
                key: "telp",
            },
            {
                name: "Alamat",
                key: "alamat",
            },
            {
              name: "Status",
              key: "active",
            },
            {
                name: "Aksi",
                key: "action",
            },
        ]);

        const tableData = reactive([]);
        const totalRecordCount = ref(0);

        const tempData = reactive(Yup.object().shape({
            id: null,
            name: Yup.string().required().label('Nama'),
            status: 0,
            tipe: Yup.string().required().label('Tipe'),
            shortname: '',
            description: '',
            store_code: '',
            address: Yup.string().required().label('Alamat'),
            logo: '',
        }))

        const listJabatan = reactive([])

        const onFilter = async () => {
            if(Object.values(filter).every(o => o == null || o.length == 0)) {
                return
            }

            filterActive.value = false
        
            loadingTable.value = true

            await getData()

            loadingTable.value = false

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async () => {
          if(Object.values(filter).every(o => o == null || o.length == 0)) {
            return
          }

          Object.keys(filter).map(key => filter[key] = null)

          loadingTable.value = true
          filterActive.value = false

          await getData()

          loadingTable.value = false
        }

        // PAGINATION
        const totalPage = ref(1)
        const currentPage = ref(1)

         const handlePageChange = async (page) => {
            loadingTable.value = true
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position

            await getData(page)
            loadingTable.value = false
        }

        const getListJabatan = async() => {
          try {
            listJabatan.splice(0)

            await ApiService.get("user_groups")
              .then(({ data }) => {
                Object.assign(listJabatan, data.data)
              })
              .catch(({ response }) => {
                console.log('Error getting user group');
              });

          } catch(e) {
            console.error(e)
          }
        }

        const getData = async (page = 1) => {
            try {
                loadingTable.value = true
                await tableData.splice(0)

                let role = filter.role ? '&role='+filter.role : '';

                await ApiService.get("user_list?page=" + page + role)
                .then(async ({ data }) => {
                    currentPage.value = 1 
                    Object.assign(tableData, data.data.data)
                    totalPage.value = data.data.pagination.total_page;
                })
                .catch(({ response }) => {
                    console.log('Error getting user list');
                }); 

            } catch(e) {
                console.log(e)
            }

            loadingTable.value = false
        }

        const onSubmit = async(type) => {
            const loading = ElLoading.service({text: 'tunggu'})

            try {
                //

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loading.close()
        }

        const viewImageSrc = ref(null)
        const viewImageTitle = ref('')
        const viewImageVisible = ref(false)

        const viewImage = (data) => {
            viewImageTitle.value = data.logo
            viewImageVisible.value = true
            viewImageSrc.value = img_url_logo.value+data.logo
        }

        onMounted(async() => {
            setCurrentPageBreadcrumbs("User Management", ["User Management"]);

            getData()
            getListJabatan()
        })

        return {
            router,
            loadingTable, tableRef, img_url_mockup, img_url_desain, img_url_logo,
            viewImage, viewImageSrc, viewImageVisible, viewImageTitle,
            listJabatan,
            onFilter, resetFilter, filter, filterActive,
            tableHeader, tableData, totalRecordCount, tempData, onSubmit,
            handlePageChange, totalPage, currentPage,
            formatIDR,
        }
    }
})
</script>